#page-empresa-home{
  width: 100%;
  min-height: 100vh;
  padding: 10px 0;
}

#page-empresa-home header{
  padding: 8px 24px;
  margin-bottom: 20px;
  text-align: center;
}

#page-empresa-home main {
  padding: 0px 10px;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  justify-content: center;
  align-items: center;
}

#page-empresa-home main h4 {
  margin-bottom: 10px;
  font-weight: 500;
}

#page-empresa-home main > p {
  margin-bottom: 10px;
  font-size: 15px;
}

#page-empresa-home main button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 5px;
  width: 240px;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 15px;
}

#page-empresa-home main .painel {
  margin-top: 10px;
  white-space: pre-line;
  text-align: center;
  font-size: 15px;
}