.produto {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0px;
  overflow: hidden;
  cursor: pointer;
}

.produto .img-expandido {
  width: 30%;
  max-width: 220px;
  min-height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.produto .content-produto {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
}

.produto .content-produto h4 {
  margin-bottom: 5px;
}

.produto .content-produto p {
  font-size: 13px;
}

.produto .content-produto span {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0px;
}