.itemCarrinhoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.itemCarrinhoContainer .img {
  width: 30%;
  max-width: 220px;
  min-height: 120px;
  max-height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 600px){
  .itemCarrinhoContainer .img {
    display: none;
    min-height: 0px;
  }
}

.itemCarrinhoContainer .content {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  width: 100%;
}

.itemCarrinhoContainer .content h4 {
  white-space: pre-line;
  font-size: 14px;
}

.itemCarrinhoContainer .qtdAndPrice{
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  width: 150px;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
