#ModalDetalhesComanda .header {
  background-color: #ff2b08;
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

#ModalDetalhesComanda .content {
  overflow: auto;
  height: calc(100vh - 110px);
  padding: 10px 15px;
}

#ModalDetalhesComanda .tableHeader {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px;
  width: 100%;
  gap: 15px;
}

#ModalDetalhesComanda .tableRow {
  width: 100%;
}

#ModalDetalhesComanda .tableFooter {
  margin-top: 5px;
  font-weight: 600;
  line-height: 25px;
}

#ModalDetalhesComanda .content button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 240px;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin: 20px auto;
  font-size: 18px;
  background-color: #ff2b08;
}