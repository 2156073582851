#ModalComandas .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  background-color: #ff2b08;
  color: #FFF;
}

#ModalComandas .header h3 {
  font-weight: 500;
}

#ModalComandas .content {
  width: 100%;
  height: 240px;
  justify-content: center;
  align-items: center;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 15px;
  gap: 10px;
}

#ModalComandas .content .box {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-color: #ff2b08;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  gap: 10px;
}

#ModalComandas .content .box p {
  text-align: center;
}