#ModalAdmRelVendasMensal .header {
  background-color: #140e44;
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

#ModalAdmRelVendasMensal .header h3 {
  font-weight: 500;
}

#ModalAdmRelVendasMensal .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
}

#ModalAdmRelVendasMensal .content div {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#ModalAdmRelVendasMensal .content select {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #000;
}

#ModalAdmRelVendasMensal .content p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  margin-bottom: 3px;
  margin-left: 15px;
}

#ModalAdmRelVendasMensal .content button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 0px;
  border-radius: 5px;
  background-color: #00b400;
  border-bottom: 2px solid #009a00;
  padding: 9px 18px;
  margin-top: 15px;
  color: #FFF;
  cursor: pointer;
}