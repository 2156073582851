@media only screen and (min-width: 601px) {
  .ItemProdDiv {
    display: flex;
    flex-direction: row;
  }

  .ItemProdDiv .img-container {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-height: 250px;
    justify-content: flex-start;
    align-items: center;
  }

  .ItemProdDiv .img {
    width: 100%;
    min-height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .ItemProdDiv .info {
    width: 75%;
  }

  .ItemProdDiv .info {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .ItemProdDiv {
    display: flex;
    flex-direction: column;
  }

  .ItemProdDiv .img-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 250px;
    justify-content: center;
    align-items: center;
  }

  .ItemProdDiv .img {
    min-width: 300px;
    min-height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .ItemProdDiv .info {
    align-items: center;
  }
}

.ItemProdDiv .info {
  display: flex;
  flex-direction: column;
  margin: 15px 10px;
}

.ItemProdDiv .info p {
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0px 5px 0px;
}

.ItemProdDiv .info p small {
  font-size: 14px;
  font-weight: 400;
}

.ItemProdDiv .info .listIngredientes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px
}

.ItemProdDiv .info .containerBtn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.ItemProdDiv .info button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #fff;
  padding: 8px 10px;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 15px;
  float: left;
  margin-top: 10px;
}

.ItemProdDiv .info .containerAdc {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e67e22;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  transition: height 0.5s, opacity 0.5s;
  height: 0px;
  opacity: 0;
}

.ItemProdDiv .info .containerAdc h4 {
  padding: 7px;
  background-color: #e67e22;
  color: #FFF;
  font-weight: 550;
  margin-bottom: 10px;
}

.ItemProdDiv .info .infoSabor {
  font-weight: 500;
  color: #004276;
  padding: 4px 30px;
  border-style: dashed;
  border-width: 2px;
  border-spacing: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

.ItemProdDiv .info .containerObs {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #004276;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  transition: height 0.5s, opacity 0.5s;
  height: 0px;
  opacity: 0;
}

.ItemProdDiv .info .containerObs h4 {
  padding: 7px;
  background-color: #004276;
  color: #FFF;
  font-weight: 550;
}

.ItemProdDiv .info textarea {
  width: 100%;
  border: 1px solid #c0c0c0;
  padding: 5px 10px;
  border-radius: 5px;
}

.ItemProdDiv .info .btnComprar {
  font-size: 24px;
  color: #fff;
  padding: 10px 20px;
  background-color: #087607;
  border-bottom: 3px solid #014f00;
  border-radius: 4px;
  cursor: pointer;
  float: left;
  margin-top: 10px;
  font-weight: 400;
}