#pgControleHome {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

#pgControleHome h2 {
  text-align: center;
}

#pgControleHome .dvTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

#pgControleHome .dvTotal .filterStatus {
  cursor: pointer;
}

#pgControleHome .dvTotal .selectedStatus {
  cursor: pointer;
  background-color: #007191;
  color: #fff;
}

#pgControleHome .dvTotal .painel {
  gap: 5px;
}

#pgControleHome .dvTotal .painel b {
  font-size: 14px;
}

#pgControleHome .painel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  border-radius: 5px;
  background-color: #FFF;
  margin: 15px 0px;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 15px 0px #eee;
  box-shadow: 0px 0px 15px 0px #eee;
  transition: all 500ms ease-in-out;
}

#pgControleHome .painel .info {
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 25px;
  transition: all 500ms ease-in-out;
  overflow: hidden;
}

#pgControleHome .painel .info hr {
  margin: 5px 0;
  border-style: dashed;
  border-width: 1px;
  border-color: #888;
}

#pgControleHome .painel .info p {
  font-size: 18px;
  font-weight: 500;
}

#pgControleHome .painel .info p small {
  font-size: 14px;
}

#pgControleHome .painel .info span {
  font-size: 14px;
  font-weight: 500;
}

#pgControleHome .painel .info span i {
  font-size: 14px;
  font-weight: normal;
  margin-left: 5px;
}

#pgControleHome .painel .info .infoConfig {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 10px;
}

#pgControleHome button {
  font: 400 16px "Roboto", sans-serif;
  outline: none;
}

#pgControleHome button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
}

#pgControleHome .btnBlue {
  min-width: 195px;
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #00bcf1 !important;
}

#pgControleHome .btnDarkBlue {
  min-width: 190px;
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #007191 !important;
}

#pgControleHome .btnOrange {
  min-width: 190px;
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #f19d00 !important;
}

#pgControleHome .btnOrange:disabled {
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #e0d1b6 !important;
}

#pgControleHome .btnGreen {
  min-width: 190px;
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #4fda94 !important;
}

#pgControleHome .btnGreen:disabled {
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #c5f3dc !important;
}