* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #ddd;
  color: #29292e;
}

body,
input,
button,
textarea {
  font: 400 16px "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  background-clip: padding-box;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ck-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ck-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
  border: 0.5px solid rgb(172, 172, 172);
}

.ck-container:hover input~.checkmark {
  background-color: #ccc;
}

.ck-container input:checked~.checkmark {
  background-color: #2196F3;
  border: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.ck-container input:checked~.checkmark:after {
  display: block;
}

.ck-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

textarea {
  resize: none;
  border: none;
  padding: 5px;
  font-size: 12px;
}

textarea:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}