#admHomePage {
  width: 100%;
  padding: 20px;
}

#admMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 10px;
  left: -300px;
  width: 300px;
  height: 100vh;
  background-color: #FFF;
  z-index: 1;
  border-right: 3px solid #140e44;
  border-top: 3px solid #140e44;
  border-radius: 0 20px 0 0;
  overflow: auto;
  transition: all 0.3s;
}

#admMenu h4 {
  text-align: center;
  margin-bottom: 15px;
}

#admMenu .divClose {
  text-align: right;
  margin: 10px 15px 0px 0px;
  font-size: 18px;
}

#admMenu span {
  margin: 5px 15px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}

#admMenu span:hover {
  opacity: 0.75;
}

#admHomePage .contentEmpresa {
  width: 100%;
  margin-bottom: 25px;
}

#admHomePage .contentEmpresa h3 {
  text-align: center;
  padding: 5px 10px;
}

#admHomePage .contentEmpresa .tab {
  -webkit-border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-topright: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  width: 150px;
  background: #FFF;
  margin-left: 30px;
}

#admHomePage .contentEmpresa .tab:before,
#admHomePage .contentEmpresa .tab:after {
    content: "";
    position: absolute;
    height: 10px;
    width: 20px;
    bottom: 0;
}

#admHomePage .contentEmpresa .tab:after {
    right: -20px;
    border-radius: 0 0 0 8px;
    -moz-border-radius: 0 0 0 8px;
    -webkit-border-radius: 0 0 0 8px;
    -webkit-box-shadow: -8px 0 0 0 #fff;
    box-shadow: -8px 0 0 0 #fff;
}

#admHomePage .contentEmpresa .tab:before {
    left: -20px;
    border-radius: 0 0 8px 0;
    -moz-border-radius: 0 0 8px 0;
    -webkit-border-radius: 0 0 8px 0;
    -webkit-box-shadow: 8px 0 0 0 #fff;
    box-shadow: 8px 0 0 0 #fff;
}

#admHomePage .contentEmpresa .context {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background: #FFF;
  border-radius: 8px;
  min-height: 50px;
  padding: 15px 15px;
  gap: 15px;
}

#admHomePage .contentEmpresa .context .mesas {
  flex: 1;
  min-width: 450px;
  min-height: 150px;
  background: #f1f1f1;
  border-radius: 5px;
}

#admHomePage .contentEmpresa .context .mesas h4 {
  margin-top: 8px;
  width: 100%;
  text-align: center;
}

#admHomePage .contentEmpresa .context .mesas .mesasContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px 15px 10px 15px;
  justify-content: center;
  gap: 10px;
  overflow: auto;
  max-height: 400px;
}

#admHomePage .contentEmpresa .context .mesas .squareMesa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  width: 130px;
  height: 80px;
  color: #FFF;
}

#admHomePage .contentEmpresa .context .mesas .squareMesa p {
  font-size: 18px;
}

#admHomePage .contentEmpresa .context .mesas .squareMesa span {
  font-size: 12px;
}

#admHomePage .contentEmpresa .context .delivery {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  min-width: 450px;
  min-height: 50px;
  padding: 5px 15px 10px 15px;
  background: #f1f1f1;
  border-radius: 5px;
  max-height: 405px;
  overflow: auto;
}

#admHomePage .contentEmpresa .context .delivery h4 {
  margin-top: 8px;
  width: 100%;
  text-align: center;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px 15px 10px 15px;
  justify-content: center;
  gap: 10px;
  overflow: auto;
  max-height: 400px;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent .tableDelivery {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent .tableDelivery .header {
  min-width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px 5px 0px 0px;
  background: #140e44;
  padding: 10px 10px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent .tableDelivery .footer {
  min-width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 0px 0px 5px 5px;
  background: #888;
  padding: 5px;
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent .tableDelivery .body {
  min-width: 700px;
  border-style: solid;
  border-color: #c0c0c0;
  border-width: 0px 1px;
  min-height: 30px;
  max-height: 260px;
  overflow: auto;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent .tableDelivery .rowDelivery {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  font-size: 12px;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent .tableDelivery .rowDelivery:hover {
  opacity: 0.7;
}

#admHomePage .contentEmpresa .context .totais {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#admHomePage .contentEmpresa .context .totais .totaisContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px;
  background: #f1f1f1;
  border-radius: 5px;
  min-width: 300px;
}

#admHomePage .contentEmpresa .context .totais .totaisContent .totaisRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

#admHomePage .contentEmpresa .context .totais .totaisContent .totaisRow:hover {
  opacity: 0.5;
}

#admHomePage .contentEmpresa .context .segundRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

#admHomePage .contentEmpresa .context .chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 300px;
  min-width: 450px;
  border-radius: 5px;
  overflow: hidden;
}

@media screen and (max-width: 820px){
  #admHomePage .contentEmpresa .context .chat {
    margin-top: 10px;
  }
}

#admHomePage .contentEmpresa .context .chat h4 {
  padding: 10px 15px;
  background: #140e44;
  color: #FFF;
  font-weight: 500;
}

#admHomePage .contentEmpresa .context .chat .chatContext {
  width: 100%;
  height: 260px;
}
#admHomePage .contentEmpresa .context .chat .messages {
  width: 100%;
  height: 213px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}

#admHomePage .contentEmpresa .context .chat .chatContext .otherMsg {
  display: flex;
  flex-direction: column;
  gap: 2px;
  background: #FFF;
  border-radius: 8px;
  align-self: flex-start;
  margin: 5px 15px;
  padding: 5px 10px;
  font-size: 14px;
}

#admHomePage .contentEmpresa .context .chat .chatContext .otherMsg span {
  font-size: 12px;
  font-weight: 500;
  color: #565656;
}

#admHomePage .contentEmpresa .context .chat .chatContext .myMsg {
  display: flex;
  flex-direction: column;
  background: #5f829e;
  color: #FFF;
  border-radius: 8px;
  align-self: flex-end;
  margin: 5px 15px;
  padding: 5px 10px;
  font-size: 14px;
}

#admHomePage .contentEmpresa .context .chat .chatContext .myMsg span {
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  text-align: right;
}

#admHomePage .contentEmpresa .context .chat .chatContext .inputMsg {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 35px;
  background: #FFF;
  margin: 5px 10px 0px 10px;
  border-radius: 8px;
  padding: 0px 10px;
}

#admHomePage .contentEmpresa .context .chat .chatContext .inputMsg input {
  flex: 1;
  margin: 3px 0px;
  border: 0px;
  outline: none;
  font-size: 14px;
}

#admHomePage .contentEmpresa .context .chat .chatContext .inputMsg .btnSend:hover {
  cursor: pointer;
}

#admHomePage .contentEmpresa .context .delivery .deliveryContent .tableDelivery .body .menuDelivery {
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}