#modal-pedidos .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

#modal-pedidos .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
}

#modal-pedidos .content p {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 100%;
}

#modal-pedidos .content button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 0px;
  border-radius: 5px;
  background-color: #00b400;
  border-bottom: 2px solid #009a00;
  padding: 9px 18px;
  margin-top: 15px;
  color: #FFF;
  cursor: pointer;
}

#modal-pedidos .content .headerList{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  border-radius: 8px 8px 0px 0px;
  margin-top: 15px;
}

#modal-pedidos .content .headerList p {
  padding: 0px;
  margin: 0px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

#modal-pedidos .content .contentList{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 0px 0px 8px 8px;
  border-width: 2px;
  border: 2px solid black;
  border-top: 0px;
}

#modal-pedidos .content .contentList .itemList{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
}

#modal-pedidos .content .contentList .itemList:nth-child(even){
  background-color: #DDD;
}

#modal-pedidos .content .contentList .itemList p {
  padding: 0px 10px;
  margin: 0px;
  font-size: 14px;
  text-align: center;
}
