#ModalListProdutos .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}


#ModalListProdutos .lista-produtos-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}

#ModalListProdutos .content {
  padding: 10px 15px;
}