 .btn-qtd-container {
   display: flex;
   flex-direction: row;
   border-width: 2px;
   border-style: solid;
   border-radius: 5px;
   justify-content: space-between;
   align-items: center;
 }

 .btn-qtd-container span {
   font-size: 16px;
   margin: 0px 10px;
 }

 .btn-qtd-container button {
   border: 0px;
   height: 30px;
   width: 30px;
   cursor: pointer;
 }

 .btn-qtd-container .icons {
   padding: 8px;
   width: 31px;
   height: 31px;
   color: white;
 }