#page-mesa-cardapio{
  width: 100%;
  min-height: 100vh;
  padding: 10px 0;
}

#page-mesa-cardapio header{
  padding: 8px 24px;
  margin-bottom: 20px;
  text-align: center;
}

@media only screen and (min-width: 601px){
  #page-mesa-cardapio header .content{
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  #page-mesa-cardapio header .content{
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
}

#page-mesa-cardapio header .content .info h4{
  font-weight: 500;
  font-size: 15px;
}

#page-mesa-cardapio header .content .info{
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 5px;
}

#page-mesa-cardapio main {
  padding: 0px 10px;
  max-width: 1120px;
  margin: 0 auto;
  flex-direction: column;
  margin-bottom: 80px;
}

#page-mesa-cardapio .faixa-grupo {
  background-color: #ff2b08;
  color: #FFF;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0px 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

#page-mesa-cardapio .faixa-grupo h3 {
  margin-left: 5px;
}

#page-mesa-cardapio .lista-produtos {
  overflow: hidden;
  height: 0;
  transition: height 0.5s;
}

#page-mesa-cardapio .lista-produtos-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 0;
  transition: height 1s;
}

#page-mesa-cardapio .Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: papayawhip;
}

#page-mesa-cardapio .ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}

#page-mesa-cardapio .icone {
  transition: transform 0.5s;
}

#page-mesa-cardapio #faixaCliqueAqui {
  overflow: hidden;
  z-index: 99;
  position: fixed;
  bottom: 31px;
  right: 60px;
  background-color: seagreen;
  padding: 8px 15px;
  border-radius: 8px;
  width: 0px;
  opacity: 0;
  font-weight: 500;
  cursor: pointer;
  transition: width 0.5s, opacity 0.5s;
}

#page-mesa-cardapio #faixaCliqueAqui p {
  width: 340px;
  color: #FFF;
}

#page-mesa-cardapio .carrinho {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 100;
  background-color: seagreen;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  cursor: pointer;
}

#page-mesa-cardapio .carrinho p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: fixed;
  right: 25px;
  bottom: 55px;
  z-index: 101;
  width: 25px;
  height: 25px;
  background-color: #000;
  border-radius: 15px;
  color: #FFF;
}

#page-mesa-cardapio .aviso {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1180px;
  border: 1px solid #cd240e;
  margin: 0 auto;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #ffe8e5;
}

#page-mesa-cardapio .aviso div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0px 25px;
  color: #000;
}

#page-mesa-cardapio .aviso div h3 {
  color: #a40000;
  font-weight: 500;
}