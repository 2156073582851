.itemProdutoComanda {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  padding: 10px 10px;
  border-radius: 8px;
}

.itemProdutoComanda:hover{
  background-color: #f3f3f3;
}

.itemProdutoComanda .contentItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.itemProdutoComanda .contentItem p {
  white-space: pre-line;
  font-size: 14px;
}