#modal-detalhes .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

#modal-detalhes .content {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px 15px;
}

#modal-detalhes .content p {
  font-size: 14px;
  padding: 3px 0px;
}

#modal-detalhes .content .footer{
  margin-top: 10px;
  text-align: right;
}

#modal-detalhes .content .footer p {
  font-size: 16px;
}

#modal-detalhes .content .headerList{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  border-radius: 8px 8px 0px 0px;
  margin-top: 15px;
}

#modal-detalhes .content .headerList p {
  padding: 0px;
  margin: 0px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  flex: 1;
}

#modal-detalhes .content .contentList{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-top: 10px;
  border-radius: 0px 0px 8px 8px;
  border-width: 2px;
  border: 2px solid black;
  border-top: 0px;
  justify-content: center;
  align-items: center;
}

#modal-detalhes .content .contentList .separator{
  width: 95%;
  height: 2px;
  background-color: #DDD;
  margin: 15px 0px;
}

#modal-detalhes .content .contentList .itemList{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 15px;
}

#modal-detalhes .content .contentList .itemList p {
  width: 100%;
  white-space: pre-line;
}