#ModalCarrinhoMesa .header {
  background-color: #ff2b08;
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

#ModalCarrinhoMesa .list-footer{
  margin-top: 10px;
}

#ModalCarrinhoMesa .list-footer h4 {
  text-align: right;
  padding: 0px 15px;
}

#ModalCarrinhoMesa h5 {
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

#ModalCarrinhoMesa .contentComandas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 15px;
  justify-content: center;
  align-items: center;
}

#ModalCarrinhoMesa .contentComandas .box {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-color: #ff2b08;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  gap: 10px;
}

#ModalCarrinhoMesa .contentComandas .box p {
  text-align: center;
}