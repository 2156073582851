#page-chama-garcom{
  width: 100%;
  min-height: 100vh;
}

#page-chama-garcom header{
  background-color: #000;
  color: #FFF;
  padding: 8px 24px;
  text-align: center;
}

#page-chama-garcom header h4 {
  margin-bottom: 10px;
  font-weight: 500;
}

#page-chama-garcom .painel {
  width: 100%;
  background-color: #ff2b08;
  color: #FFF;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}

#page-chama-garcom .painel h4 {
  text-align: center;
  line-height: 20px;
}

#page-chama-garcom .painel p {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  color: #ffca01;
  margin: 15px 0px;
  letter-spacing: 3px;
  /* text-shadow: -2px  2px 0 #000,
                2px  2px 0 #000,
                2px -2px 0 #000,
               -2px -2px 0 #000; */
}

#page-chama-garcom main {
  padding: 0px 10px;
  max-width: 1120px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

#page-chama-garcom main > p {
  margin-bottom: 10px;
  font-size: 15px;
}

#page-chama-garcom main button {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-radius: 5px;
  width: 240px;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 18px;
}