#ModalProdutoQtd hr {
  width: 100%;
  border: solid 1px #ddd;
  margin: 15px 0px;
}

#ModalProdutoQtd .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

@media only screen and (min-width: 601px) {
  #ModalProdutoQtd .content {
    display: flex;
    flex-direction: row;
  }

  #ModalProdutoQtd .content .img-container {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-height: 250px;
    justify-content: flex-start;
    align-items: center;
  }

  #ModalProdutoQtd .content .img {
    width: 100%;
    min-height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }

  #ModalProdutoQtd .content .info {
    width: 75%;
  }

  #ModalProdutoQtd .content .info {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  #ModalProdutoQtd .content {
    display: flex;
    flex-direction: column;
  }

  #ModalProdutoQtd .content .img-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 250px;
    justify-content: center;
    align-items: center;
  }

  #ModalProdutoQtd .content .img {
    min-width: 300px;
    min-height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }

  #ModalProdutoQtd .content .info {
    align-items: center;
  }
}

#ModalProdutoQtd .content .info {
  display: flex;
  flex-direction: column;
  margin: 15px 10px;
}

#ModalProdutoQtd .content .info p {
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0px 5px 0px;
}

#ModalProdutoQtd .info p small {
  font-size: 14px;
  font-weight: 400;
}

#ModalProdutoQtd .content .info .listIngredientes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px
}

#ModalProdutoQtd .content .info .containerBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px 10px 0px;
}

#ModalProdutoQtd .content .info .btnObsAdc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #fff;
  padding: 8px 10px;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 15px;
  float: left;
  margin-top: 5px;
}

#ModalProdutoQtd .content .info #containerAdc {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e67e22;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  transition: height 0.5s, opacity 0.5s;
  height: 0px;
  opacity: 0;
}

#ModalProdutoQtd .content .info #containerAdc .adcItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
}

#ModalProdutoQtd .content .info #containerAdc .adcItemContainer .itemName {
  flex: 1;
}

#ModalProdutoQtd .content .info #containerAdc h4 {
  padding: 7px;
  background-color: #e67e22;
  color: #FFF;
  font-weight: 550;
  margin-bottom: 10px;
}

#ModalProdutoQtd .content .info #containerObs {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #004276;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  transition: height 0.5s, opacity 0.5s;
  height: 0px;
  opacity: 0;
}

#ModalProdutoQtd .content .info #containerObs h4 {
  padding: 7px;
  background-color: #004276;
  color: #FFF;
  font-weight: 550;
}

#ModalProdutoQtd .content .info textarea {
  width: 100%;
  border: 1px solid #c0c0c0;
  padding: 5px 10px;
  border-radius: 5px;
}

#ModalProdutoQtd .content .info .btnComprar {
  font-size: 24px;
  color: #fff;
  padding: 10px 20px;
  background-color: #087607;
  border-bottom: 3px solid #014f00;
  border-radius: 4px;
  cursor: pointer;
  float: left;
  margin-top: 10px;
  font-weight: 400;
}

#ModalProdutoQtd .listGpEspec {
  width: 100%;
}

#ModalProdutoQtd .listGpEspec h3 {
  text-align: center;
}

#ModalProdutoQtd .listGpEspec .GpEspec {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  padding: 0px 15px;
}

#ModalProdutoQtd .listGpEspec .GpEspec .infoGpEspec {
  font-size: 18px;
  vertical-align: "center";
  flex: 1;
  margin: 0px 10px;
}

#ModalProdutoQtd .listGpEspec .infoGpEspec span {
  font-size: 14px;
}

#ModalProdutoQtd .aviso {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  max-width: 1180px;
  border: 1px solid #cd240e;
  margin: 0 15px;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #ffe8e5;
}