#ModalProdutoDivisor hr {
  border: solid 1px #ddd;
  margin: 15px 0px;
}

#ModalProdutoDivisor .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

@media only screen and (min-width: 601px) {
  #ModalProdutoDivisor .content {
    display: flex;
    flex-direction: row;
  }

  #ModalProdutoDivisor .content .info {
    width: 75%;
  }

  #ModalProdutoDivisor .content .info {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  #ModalProdutoDivisor .content {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
  }

  #ModalProdutoDivisor .content .info {
    align-items: center;
    text-align: center;
  }
}

#ModalProdutoDivisor .content .info {
  display: flex;
  flex-direction: column;
  margin: 15px 10px;
}

#ModalProdutoDivisor .listGpEspec h3 {
  text-align: center;
}

#ModalProdutoDivisor .listGpEspec .GpEspec {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  padding: 0px 15px;
}

#ModalProdutoDivisor .listGpEspec .GpEspec .infoGpEspec {
  font-size: 18px;
  vertical-align: "center";
  flex: 1;
  margin: 0px 10px;
}

#ModalProdutoDivisor .listGpEspec .infoGpEspec span {
  font-size: 14px;
}

#ModalProdutoDivisor .btnComprar {
  font-size: 24px;
  color: #fff;
  padding: 10px 20px;
  border: none;
  background-color: #087607;
  border-bottom: 3px solid #014f00;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0px;
  font-weight: 400;
}

#ModalProdutoDivisor .aviso {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  max-width: 1180px;
  border: 1px solid #cd240e;
  margin: 0 15px;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #ffe8e5;
}