#ModalCarrinho .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

#ModalCarrinho .list-footer {
  margin-top: 10px;
}

#ModalCarrinho .list-footer h4 {
  text-align: right;
  padding: 0px 15px;
}

#ModalCarrinho #finalizarContent {
  margin: 10px 15px;
  margin-top: 25px;
  border: solid 2px black;
  border-radius: 5px;
  overflow: hidden;
  height: 150px;
  transition: height 0.5s;
}

#ModalCarrinho #finalizarContent h4 {
  padding: 10px 15px;
}

#ModalCarrinho #finalizarContent .container {
  padding: 15px;
}

#ModalCarrinho #finalizarContent .container span {
  font-size: 14px;
}

#ModalCarrinho #finalizarContent .container .phoneContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#ModalCarrinho #finalizarContent .container .phoneContainer button {
  border: 0px;
  border-radius: 5px;
  background-color: #00b400;
  border-bottom: 2px solid #009a00;
  padding: 5px 18px;
  margin: 1px 0px;
  color: #FFF;
  cursor: pointer;
}

#ModalCarrinho #finalizarContent .container .infoCliente {
  margin: 10px 0px;
}

#ModalCarrinho #finalizarContent .container .infoCliente .listaEnderecos {
  display: flex;
  flex-direction: column;
  gap: 10px
}

#ModalCarrinho .btnVerde {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  gap: 5px;
  align-self: center;

  background-image: -ms-linear-gradient(90deg, rgb(8, 118, 7) 0%, rgb(1, 148, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(8, 118, 7) 0%, rgb(1, 148, 0) 100%);
  border: 1px solid #015500;
  color: #FFF;
  font-size: 12px;
  cursor: pointer;
}

#ModalCarrinho #finalizarContent .container .infoCliente .listaEnderecos button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  gap: 5px;
  align-self: center;

  background-image: -ms-linear-gradient(90deg, rgb(8, 118, 7) 0%, rgb(1, 148, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(8, 118, 7) 0%, rgb(1, 148, 0) 100%);
  border: 1px solid #015500;
  color: #FFF;
  font-size: 12px;
  cursor: pointer;
}

#ModalCarrinho #finalizarContent .container .infoCliente .optionsPgto {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

#ModalCarrinho #finalizarContent .container .infoCliente .optionsPgto p {
  font-size: 14px;
  font-weight: 600;
}

#ModalCarrinho #finalizarContent .container .infoCliente .CPFnota {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#ModalCarrinho #finalizarContent .container .infoCliente .CPFnota p {
  font-size: 14px;
  font-weight: 600;
}

#ModalCarrinho #finalizarContent .container .infoCliente .CPFnota select {
  width: 120px;
  height: 45px;
  padding: 5px 15px;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  #ModalCarrinho #finalizarContent .container .infoCliente .optionsPgto {
    flex-direction: column;
  }

  #ModalCarrinho #finalizarContent .container .infoCliente .CPFnota {
    max-width: 230px;
    width: 100%;
    padding: 0px 15px;
    flex-direction: column;
  }

  #ModalCarrinho #finalizarContent .container .infoCliente .CPFnota select {
    width: 100%;
  }
}

#ModalCarrinho #finalizarContent .container .infoCliente .containerPgto {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 20px;
}

#ModalCarrinho #finalizarContent .container .infoCliente .containerPgto div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 150px;
  height: 150px;
  background-color: rgb(8, 118, 7);
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  font-weight: 600;
  text-align: center;
  color: #FFF;
  cursor: pointer;
}

#ModalCarrinho #finalizarContent .container .infoCliente .btnContinuarComprando {
  border: 0px;
  border-radius: 5px;
  background-color: #00b400;
  border-bottom: 2px solid #009a00;
  padding: 5px 18px;
  margin: 15px 0px;
  color: #FFF;
  cursor: pointer;
}

#ModalCarrinho .aviso {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  max-width: 1180px;
  border: 1px solid #cd240e;
  margin: 15px;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #ffe8e5;
}